import ThriveCenterSubscription from "@pages/Thrive/components/ThriveCenterSubscription";

type ThriveSubscriptionProps = {
  subscriptionTitle?: string;
  subscriptionDescription?: string;
};

export default function ThriveWantMore({
  subscriptionTitle,
  subscriptionDescription,
}: ThriveSubscriptionProps): React.ReactElement {
  return (
    <div
      className="overflow-hidden font-inter"
      data-testid="thrive-home-start-conversation-component"
    >
      <div className="relative -ml-2 w-[101vw] -rotate-2 border-b-2 border-black pb-60"></div>

      <ThriveCenterSubscription
        title={subscriptionTitle}
        description={subscriptionDescription}
        className="-mt-40 lg:-mt-28"
        subscriptionFormClassName="sm:w-10/12 md:w-8/12"
      />
    </div>
  );
}
