import { Field } from "@app/types/Cue";
import { Name, Type } from "@app/types/enums";
import { useTextRangesWithAnnotations } from "@hooks/useTextRangesWithAnnotations";
import { getFieldValue } from "@util/helpers";

export type PullQuoteProps = {
  fields: Field[];
  className?: string;
};

export default function PullQuote({
  className,
  fields,
}: PullQuoteProps): React.ReactElement {
  const label = getFieldValue(fields, Name.QuoteLabel);
  const quote = getFieldValue(fields, Name.Quote);
  const quotee = getFieldValue(fields, Name.Quotee);
  const credit = getFieldValue(fields, Name.QuoteCredit);
  const excludeForBT = getFieldValue(fields, Name.QuoteRule);
  const annotation =
    fields.find((field) => field.name === Name.Quote)?.annotations || [];

  const { componentToRender } = useTextRangesWithAnnotations(
    typeof quote === "string" ? quote : "",
    annotation
  );

  return (
    <div
      data-story-element={Type.PullQuote}
      className={className}
      data-testid="pull-quote-component"
    >
      {label && excludeForBT === "false" ? (
        <div
          className="font-public-sans text-base font-normal uppercase text-gray-850"
          data-testid="pull-quote-component-label"
        >
          {label}
        </div>
      ) : null}

      {quote ? (
        <blockquote
          className="mb-4 font-notoSerif text-7xl font-light tracking-2% text-gray-850 lg:text-11xl"
          data-testid="pull-quote-component-quote"
        >
          “{componentToRender}”
        </blockquote>
      ) : null}

      {quotee ? (
        <div
          className="flex font-public-sans text-sm font-light tracking-2% text-gray-850 lg:text-xl"
          data-testid="pull-quote-component-quotee"
        >
          <div>
            <span>—</span>
          </div>

          <div>
            <span>{quotee}</span>
          </div>
        </div>
      ) : null}

      {credit && excludeForBT === "false" ? (
        <div
          className="font-public-sans text-base font-normal text-gray-850"
          data-testid="pull-quote-component-credit"
        >
          {credit}
        </div>
      ) : null}
    </div>
  );
}
