import { handleAlacrity } from "@api/endpoint-handlers/bff-functions/Alacrity/Alacrity";
import {
  ArticleDataObject,
  CookGQL,
  RelatedStory,
  SanitizedRelatedStory,
  seeAlso,
} from "@app/types/Cue";
import { ArticleContext } from "@app/types/Page";
import { genSanitizedRelatedStoriesQuery } from "@caas/queries/genSanitizedRelatedStoriesQuery";
import { genSectionQuery } from "@caas/queries/genSectionQuery";
import { genSeeAlsoQuery } from "@caas/queries/genSeeAlsoQuery";
import { queryCaasOpenSearch } from "@caas/queryCaasOpenSearch";
import {
  LAMBDA_REQUEST_ACTION_TYPES,
  LAMBDA_RESPONSE_STATUS,
  LambdaResponsePayload,
} from "@pages/Article/types/Alacrity";
import { ResponseType, TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { ENVIRONMENT } from "@util/constant";
import { getEnvironmentDomain } from "@util/helpers";
import { HttpStatusCode } from "axios";

export const processArticleData = async (
  article: ArticleDataObject,
  isPreview?: boolean,
  sort?: number[]
): Promise<TRouteWithRedirect<ArticleContext, string>> => {
  if (article && article.relatedStories && article.relatedStories.length > 0) {
    const sanitizedRelatedStories = await fetchSanitizedRelatedStories(
      article.relatedStories
    );

    if (sanitizedRelatedStories) {
      article = {
        ...article,
        sanitizedRelatedStories: sanitizedRelatedStories,
      };
    }
  }
  const urlPath = article.urlPath;

  if (typeof urlPath === "undefined") {
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: HttpStatusCode.InternalServerError,
      payload: "Article URL path is undefined",
    };
  }

  const longURL = `${getEnvironmentDomain(ENVIRONMENT)}${urlPath}`;
  const shortUrl = await fetchShortUrl(longURL);

  const artContext = {
    ...article,
    sort: sort,
    shortUrl: shortUrl || longURL,
  };

  let sectionContext: ArticleDataObject[] = [];
  let seeAlsoContext: Partial<ArticleDataObject> = {};
  const uniqueName = artContext?.sections?.[0]?.uniqueName;
  const keyword = artContext?.tags?.[0]?.urlPath;
  const externalURL = artContext?.displaySetting?.externalURL;

  // Pass redirect response if externalURL is present.
  if (externalURL && longURL !== externalURL) {
    return {
      type: ResponseType.REDIRECT,
      statusCode: 301,
      target: externalURL,
    };
  }

  if (uniqueName) {
    const sectionQuery = genSectionQuery(uniqueName, 9);
    const sectionResponse = await queryCaasOpenSearch(sectionQuery);
    const sectionHits = sectionResponse?.payload?.hits?.hits ?? [];

    sectionContext = sectionHits.map(
      ({
        _source: {
          data: {
            context: {
              id,
              title,
              authors,
              created,
              edited,
              updated,
              urlPath,
              relatedContributorProfile,
            },
          },
        },
      }) => {
        return {
          id: id,
          title: title,
          authors: authors,
          created: created,
          edited: edited,
          updated: updated,
          urlPath: urlPath,
          relatedContributorProfile: relatedContributorProfile,
          slug: urlPath,
        };
      }
    );
  }

  if (keyword) {
    const excludedId = artContext.id;
    const seeAlsoQuery = genSeeAlsoQuery(keyword, excludedId);
    const seeAlsoResponse = await queryCaasOpenSearch(seeAlsoQuery);
    const seeAlsoHits = seeAlsoResponse?.payload?.hits?.hits ?? [];
    seeAlsoContext = seeAlsoHits?.map((article) => {
      const data = article._source.data.context;
      return { ...data, slug: data.urlPath };
    })[0];
  }

  const seeAlso = {
    articleId: seeAlsoContext?.id,
    title: seeAlsoContext?.title,
    slug: seeAlsoContext?.urlPath,
  } as seeAlso;

  return {
    type: ResponseType.SUCCESS,
    statusCode: 200,
    payload: {
      kind: "art",
      data: {
        cook: {
          data: {
            context: artContext,
          },
        } as CookGQL,
        sectionNews: sectionContext,
        seeAlso: seeAlso,
        isPreview: isPreview,
      },
    },
  };
};

export const fetchShortUrl = async (urlPath: string) => {
  const payload = {
    action: LAMBDA_REQUEST_ACTION_TYPES.GENERATE_SHORTEN_URL,
    data: {
      articleURL: urlPath,
    },
  };

  try {
    const response: LambdaResponsePayload = await handleAlacrity(payload);

    // Guard clauses
    if (response.status !== LAMBDA_RESPONSE_STATUS.SUCCESS) return;
    return response?.data?.shortURL;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(
      `Error occurred while fetching short URL for urlPath: ${urlPath}. Details: ${(err as Error).message}`
    ); // eslint-disable-line no-console
    return;
  }
};

/**
 * Fetches sanitized related stories based on provided related stories.
 *
 * @param relatedStories Array of related stories.
 * @returns Array of sanitized related stories.
 */
export const fetchSanitizedRelatedStories = async (
  relatedStories: RelatedStory[]
) => {
  // The current title value from the payload of CUE contains relatedStories.field.title as a slug version of the title
  // and not the actual title, hence another OS query is made to fetch the necessary values.
  // TODO: Remove this part once CUE payload is modified and re-indexing is done on CaaS.

  const related_stories_ids = relatedStories.map((story) => story.content.id);
  const query = genSanitizedRelatedStoriesQuery(related_stories_ids);
  const response = await queryCaasOpenSearch(query);

  const sanitizedRelatedStories: SanitizedRelatedStory[] = [];

  const hits = response.payload?.hits.hits;

  for (const hit of hits) {
    const { id, title, media, sections } = hit._source.data.context;
    const urlPath =
      hit._source.data.context.displaySetting?.externalURL ||
      hit._source.data.context.urlPath;

    sanitizedRelatedStories.push({
      id,
      title,
      urlPath,
      media,
      sections,
    });
  }

  return sanitizedRelatedStories;
};
