import { ReactElement, useState } from "react";
import Advertisement from "@app/components/Advertisement/Advertisement";
import { RouteFactory } from "@app/routePaths";
import { AdvertisementTypeEnum } from "@app/types/enums";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Container from "@components/Container/Container";
import Header from "@components/Header/Header";
import LoadMore, { Loading } from "@components/LoadMore/LoadMore";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import { useQuerylySearchFetchData } from "@hooks/useQuerylySearchFetchData";
import { useQuerylyTrendingSearch } from "@hooks/useQuerylyTrendingSearch";
import { GoogleAdsSlotFactory } from "@util/helpers";

import SearchFiltering from "./components/SearchFiltering/SearchFiltering";
import SearchInput from "./components/SearchInput";
import SearchResult from "./components/SearchResult";
import SearchTrendingKeywords from "./components/SearchTrendingKeywords";
import {
  FilteringfacetedObjectEnum,
  FilteringSortEnum,
  SearchSelectedFacetedObj,
} from "./types";

export default function SearchPage(): ReactElement {
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({
      targeting: { key: "page", value: "listing" },
    });

  const [query, setSearchKeyword] = useState("");
  const [sortBy, setSortBy] = useState<FilteringSortEnum>(
    FilteringSortEnum.Relevance
  );
  const [selectedFacetedAllData, setSelectedFacetedData] =
    useState<SearchSelectedFacetedObj[]>();

  const trendingKeywords = useQuerylyTrendingSearch();

  const {
    fetchSearchData,
    searchData,
    isLoading,
    facetedData,
    displayLoadMore,
    handleClearSearchData,
  } = useQuerylySearchFetchData();

  const handleSelectedKeyword = (query: string) => {
    setSearchKeyword(query);
    fetchSearchData({
      query,
      isDefaultEndIndex: true,
      sortBy,
      selectedFacetedAllData,
      isHandleLoadMore: false,
    });
  };

  const handleSearchNewList = (query: string) => {
    setSearchKeyword(query);
    fetchSearchData({
      query: query,
      isDefaultEndIndex: true,
      sortBy,
      selectedFacetedAllData,
      isHandleLoadMore: false,
    });
  };

  const handleLoadMore = async () => {
    fetchSearchData({
      query,
      isDefaultEndIndex: false,
      sortBy,
      selectedFacetedAllData,
      isHandleLoadMore: true,
    });
  };

  const handleSetSortBy = async (sortBy: FilteringSortEnum) => {
    setSortBy(sortBy);
    fetchSearchData({
      query,
      isDefaultEndIndex: true,
      sortBy,
      selectedFacetedAllData,
      isHandleLoadMore: false,
    });
  };

  const handleSelectedFaceted = (
    facetedKey: FilteringfacetedObjectEnum,
    facetedValue: string
  ) => {
    const tmpFacetedData = selectedFacetedAllData || [];
    const tmpFacetedDataIndex = tmpFacetedData?.findIndex(
      (x) => x.facetedKey === facetedKey
    );

    if (tmpFacetedDataIndex >= 0 && facetedValue === "") {
      tmpFacetedData.splice(tmpFacetedDataIndex, 1);
    } else if (tmpFacetedDataIndex >= 0) {
      tmpFacetedData[tmpFacetedDataIndex].facetedValue = facetedValue;
    } else {
      tmpFacetedData.push({
        facetedKey,
        facetedValue,
      });
    }

    setSelectedFacetedData(tmpFacetedData);

    fetchSearchData({
      query,
      isDefaultEndIndex: true,
      sortBy,
      selectedFacetedAllData: tmpFacetedData,
      isHandleLoadMore: false,
    });
  };

  const handleClearSearch = () => {
    handleClearSearchData();
    setSearchKeyword("");
    setSelectedFacetedData(undefined);
  };

  return (
    <>
      <MetaTags
        title={renderPageTitleV2({
          kind: "static",
          title: "Search",
        })}
        description="Read more at The Business Times."
        slug={RouteFactory.search()}
      />

      <div className="w-full" data-testid="search-page-content">
        <Advertisement
          rootClassName="min-h-[50px] lg:min-h-[250px] bg-gray-125 py-1 md:py-3 border-b border-gray-175"
          adUnitProps={{
            type: AdvertisementTypeEnum.LB1,
            slot: GoogleAdsSlotFactory.lb1("search"),
            adsClassName:
              "min-h-[50px] lg:min-h-[250px] flex flex-col justify-center items-center",
          }}
        />

        <PrestitialAdWrapper
          slot={GoogleAdsSlotFactory.prestitial("search")}
          {...{
            isPrestitialEnabled,
            topOverlayImpressions,
            topOverlayValidity,
          }}
        />

        <Header />

        <Container rootClassName="pt-6 pb-18">
          <div className="mx-auto mb-4 w-full px-0 text-gray-850 md:w-10/12 md:px-3 lg:w-8/12">
            <SearchInput
              value={query}
              searchNewList={(query) => {
                handleSearchNewList(query);
              }}
              handleClearSearchData={handleClearSearch}
            />

            <Loading isLoading={isLoading} />

            {searchData?.length === 0 && !isLoading ? (
              <div className="py-6 lg:pb-0">
                <p className="mb-1 font-poppins text-lg font-semibold lg:text-xl">
                  No results found for {query}
                </p>
                <p className="mb-0 font-poppins text-base lg:text-lg">
                  Make sure all words are spelled correctly or try different
                  keywords.
                </p>
              </div>
            ) : null}

            <>
              {(trendingKeywords.length > 0 && !searchData) ||
              searchData?.length === 0 ? (
                <SearchTrendingKeywords
                  keywords={trendingKeywords}
                  setSelectedKeyword={(query: string) => {
                    handleSelectedKeyword(query);
                  }}
                />
              ) : null}
            </>

            <>
              {searchData && searchData.length > 0 ? (
                <>
                  <SearchFiltering
                    sortBy={sortBy}
                    setSortBy={(sortBy) => handleSetSortBy(sortBy)}
                    facetedSearchAllData={facetedData}
                    setSelectedFacetedObj={(
                      facetedKey: FilteringfacetedObjectEnum,
                      facetedValue: string
                    ) => {
                      handleSelectedFaceted(facetedKey, facetedValue);
                    }}
                    selectedFacetedAllData={selectedFacetedAllData}
                  />

                  {searchData.map((result, index) => {
                    return (
                      <SearchResult
                        key={`${result.title}${index}`}
                        result={result}
                        cardCount={index}
                      />
                    );
                  })}

                  {displayLoadMore ? (
                    <LoadMore
                      rootClassName="my-4 border-y"
                      onLoadMore={handleLoadMore}
                      loadText="SHOW MORE"
                    />
                  ) : null}
                </>
              ) : null}
            </>
          </div>
        </Container>
      </div>

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.CATFISH,
          slot: GoogleAdsSlotFactory.catfish("search"),
        }}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.ABM,
          slot: GoogleAdsSlotFactory.abm(),
        }}
      />
    </>
  );
}
