import { HtmlHTMLAttributes } from "react";
import { cn } from "@util/helpers";

type SeasonDividerProps = Pick<
  HtmlHTMLAttributes<HTMLDivElement>,
  "className"
> & {
  season?: string;
  year: number;
};

export default function SeasonDivider({
  className,
  season,
  year,
}: SeasonDividerProps): React.ReactElement {
  if (typeof season === "undefined") return <></>;

  return (
    <div className={cn("flex items-center justify-center", className)}>
      <div className="mr-8 h-[1px] w-full flex-1 bg-verticals-lifestyle-luxe" />

      <p className="mb-0 flex-none font-ivyMode text-lg font-semibold uppercase tracking-17% text-gray-850">
        {season} {year}
      </p>

      <div className="ml-8 h-[1px] w-full flex-1 bg-verticals-lifestyle-luxe" />
    </div>
  );
}
