import { ArticleDataObject } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import {
  getKickerFromArticleDataObject,
  isArticleUpdated,
} from "@util/helpers";
import cx from "classnames";

export default function PodcastsStories({
  article,
  index,
  disablePaywall = false,
}: {
  article: ArticleDataObject;
  index: number;
  disablePaywall?: boolean;
}): React.ReactElement {
  const isLeadArticle = index === 0;
  const _media =
    article.media && "length" in article.media
      ? article.media?.[0]
      : article.media;
  const media = isLeadArticle ? _media : undefined;
  const variant: CardVariations = {
    image: {
      position: "left",
      width: isLeadArticle ? "w-1/2" : "w-full",
      filters: [
        {
          breakpoint: "(min-width: 1200px)",
          w: 768,
          h: 512,
          dpr: 1,
          f: "webp",
        },
        { breakpoint: "(min-width: 992px)", w: 624, h: 416, dpr: 1, f: "webp" },
        { breakpoint: "(min-width: 768px)", w: 696, h: 464, dpr: 1, f: "webp" },
        { w: 390, h: 260, dpr: 1, f: "webp" },
      ],
    },
    content: {
      width: isLeadArticle ? "w-1/2" : "w-full",
      extraClass: isLeadArticle ? "pl-4" : "",
    },
    kicker: {
      color: "text-gray-515",
    },
    title: {
      color: "!text-gray-850",
      size: isLeadArticle ? "md:text-4xl" : "",
    },
  };

  return (
    <div
      className={cx("story border-t border-gray-175 py-3 lg:order-1", {
        "w-full": isLeadArticle,
        "w-full lg:w-3/12": !isLeadArticle,
        "border-none": isLeadArticle,
      })}
    >
      <BasicCard
        id={article.id}
        slug={article.slug}
        key={index}
        title={article.displaySetting?.displayHeadline || article.title}
        media={media}
        kicker={
          article.kicker?.fields?.[0]?.value ||
          getKickerFromArticleDataObject(article)
        }
        paywall={
          !disablePaywall ? article.paywall?.contentAccess === "1" : false
        }
        blurb={isLeadArticle ? article.blurb : undefined}
        variations={variant}
        rootClassName={cx("h-full border-gray-175", {
          "lg:border-r lg:pr-3": !isLeadArticle && index !== 4,
          "lg:pl-3": !isLeadArticle && index !== 1,
        })}
        edited={
          isArticleUpdated(article.updated, article.edited)
            ? article.edited
            : undefined
        }
        updated={!isLeadArticle ? article.updated : undefined}
        hasUpdatedTimestampDisplay={
          article.displaySetting?.hasUpdatedTimeDisplayed
        }
        defaultImage={
          isLeadArticle
            ? {
                directoryName: "podcasts",
              }
            : undefined
        }
      />
    </div>
  );
}
