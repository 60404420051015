import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import chevronLeft from "@assets/icon-chevron-left-white.svg";
import chevronRight from "@assets/icon-chevron-right-white.svg";
import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import PulseEndingScreen from "@pages/Pulse/components/PulseEndingScreen";
import useSendPulseGaData from "@pages/Pulse/hooks/useSendPulseGaData";
import type { PulsePageProps } from "@pages/Pulse/utils/types";
import { cn } from "@util/helpers";

import PulseIndividualArticle from "./PulseIndividualDesktopArticle";

export default function PulseDesktopCarousel({
  sortedPulseData,
  currentSlide,
  setCurrentSlide,
  setCurrentSlideId,
}: PulsePageProps): React.ReactElement {
  const totalLength = sortedPulseData.length + 1;
  const navigate = useNavigate();

  const { sendTrackingData } = useSendPulseGaData();

  const handleDelayForTracking = useCallback(
    (id: string) => {
      const myTimeout = setTimeout(() => {
        if (
          window.document.getElementById(id)?.getAttribute("data-is-active") ===
          "true"
        ) {
          sendTrackingData("", "", id, sortedPulseData);
        }
      }, 1000);

      return () => {
        clearTimeout(myTimeout);
      };
    },
    [sortedPulseData, sendTrackingData]
  );

  const changeUrlPath = useCallback(
    (index: number) => {
      if (index === totalLength - 1) {
        navigate(RouteFactory.pulseSlide("finished"), { replace: true });
        sendTrackingData("pulse outro", "pulse/outro");
        return;
      }

      setCurrentSlideId(sortedPulseData[index].id);
      navigate(RouteFactory.pulseSlide(sortedPulseData[index].id));
      handleDelayForTracking(sortedPulseData[index].id);
    },
    [
      sortedPulseData,
      totalLength,
      navigate,
      setCurrentSlideId,
      handleDelayForTracking,
      sendTrackingData,
    ]
  );

  const handleNext = useCallback(() => {
    if (currentSlide + 1 > totalLength - 1) return;
    setCurrentSlide(currentSlide + 1);
    changeUrlPath(currentSlide + 1);
  }, [currentSlide, totalLength, changeUrlPath, setCurrentSlide]);

  const handlePrev = useCallback(() => {
    if (currentSlide - 1 < 0) return;
    setCurrentSlide(currentSlide - 1);
    changeUrlPath(currentSlide - 1);
  }, [currentSlide, changeUrlPath, setCurrentSlide]);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      const { code } = e;

      if (code === "ArrowRight") {
        handleNext();
      } else if (code === "ArrowLeft") {
        handlePrev();
      }
    },
    [handleNext, handlePrev]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress, true);

    return function () {
      window.removeEventListener("keydown", handleKeyPress, true);
    };
  }, [handleKeyPress]);

  const cardWidth = 430;
  const smallCardWidth = 230;
  const padding = 24;
  const arrowSize = 36;

  const arrowTotalWidth = arrowSize + padding;
  const diffBetweenSmallAndBigCard =
    cardWidth + padding - (smallCardWidth + padding);

  if (sortedPulseData.length === 0) return <> </>;

  return (
    <div
      data-testid="pulse-desktop-carousel-components"
      className="relative z-10 h-full w-full"
    >
      <div
        className="relative left-1/2 top-1/2 h-[650px] -translate-x-1/2 -translate-y-1/2"
        style={{ width: `${cardWidth}px` }}
      >
        <div
          className="absolute z-20 h-full min-w-[340px] bg-[#121212]"
          style={{
            left: `calc(-1 * ${300 + arrowTotalWidth + padding * 2}px)`,
          }}
        />

        <button
          className={cn(
            REMOVE_FOCUS_CSS,
            "absolute top-1/2 z-30 -translate-y-1/2 rounded-full bg-[#353535] p-1 transition-all duration-300 hover:bg-[#555555] active:scale-75",
            { "pointer-events-none opacity-0": currentSlide === 0 }
          )}
          style={{ left: `calc(-1 * ${arrowSize + padding}px)` }}
          onClick={handlePrev}
        >
          <img
            src={chevronLeft}
            className="h-6 w-6"
            height={24}
            width={24}
            alt="left arrow"
          />
        </button>

        <button
          className={cn(
            REMOVE_FOCUS_CSS,
            "absolute top-1/2 z-30 -translate-y-1/2 rounded-full bg-[#353535] p-1 transition-all duration-300 hover:bg-[#555555] active:scale-75",
            {
              "pointer-events-none opacity-0": currentSlide === totalLength - 1,
            }
          )}
          style={{ right: `calc(-1 * ${arrowSize + padding}px)` }}
          onClick={handleNext}
        >
          <img
            src={chevronRight}
            className="h-6 w-6"
            height={24}
            width={24}
            alt="right arrow"
          />
        </button>

        <div className="absolute -top-4 left-1/2 z-10 flex -translate-x-1/2 justify-center pb-4">
          {Array.from({ length: totalLength }).map((_, index) => {
            return (
              <div
                key={`pulse-desktop-carousel-pagination-${index}`}
                className={cn(
                  "mx-1 h-1 w-1 rounded-full bg-gray-650 transition-all duration-700",
                  { "bg-white": index <= currentSlide },
                  {
                    "w-4 bg-white": index === currentSlide,
                  }
                )}
              />
            );
          })}
        </div>

        {Array.from({ length: totalLength }).map((_, index) => {
          const article = sortedPulseData[index];
          const id = article?.id || "finished";

          return (
            <div
              key={id}
              id={id}
              className="group absolute flex h-full items-center justify-center text-white transition-[left] duration-300"
              style={{
                left:
                  currentSlide === index
                    ? `0`
                    : currentSlide > index
                      ? `calc(-1 * ${smallCardWidth + arrowTotalWidth}px)`
                      : currentSlide + 1 === index
                        ? `calc((${cardWidth + padding}px * ${index - currentSlide}) + ${arrowTotalWidth}px)`
                        : `calc((${smallCardWidth + padding}px * ${index - currentSlide}) + ${arrowTotalWidth}px + ${diffBetweenSmallAndBigCard}px)`,
              }}
              data-is-active={currentSlide === index}
              data-is-prev={currentSlide > index}
              data-is-next={currentSlide < index}
            >
              {index === totalLength - 1 ? (
                <PulseEndingScreen
                  key="ending-screen"
                  cardWidth={cardWidth}
                  smallCardWidth={smallCardWidth}
                  currentSlide={currentSlide}
                  index={index}
                />
              ) : (
                <PulseIndividualArticle
                  article={article}
                  cardWidth={cardWidth}
                  smallCardWidth={smallCardWidth}
                  currentSlide={currentSlide}
                  index={index}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
