import { useState } from "react";
import { SanitizedSectionArticle } from "@app/types/Cue";
import { sortByPublishedDate } from "@caas/queries/constant";
import BasicCard from "@components/ArticleCard/BasicCard";
import LoadMore from "@components/LoadMore/LoadMore";
import { getSeasonBasedOnDate } from "@pages/Section/layouts/Lifestyle/helpers";
import { cn } from "@util/helpers";
import axios, { HttpStatusCode } from "axios";
import dayjs from "dayjs";

import SeasonDivider from "./SeasonDivider";

type LifestyleLuxeArticlesProps = {
  articles: SanitizedSectionArticle[];
  articleIds: string[];
  todaysSeason?: string;
};

const PAGE_SIZE = 12;

export default function LifestyleLuxeArticles({
  articles,
  articleIds,
  todaysSeason,
}: LifestyleLuxeArticlesProps): React.ReactElement {
  const [stories, setStories] = useState<SanitizedSectionArticle[]>(articles);

  const loadMore = async () => {
    const page =
      stories.length === articles.length ? 1 : stories.length / PAGE_SIZE + 1;

    const response = await axios.post<SanitizedSectionArticle[]>(
      "/_plat/api/v1/more-stories",
      {
        category: "lifestyle_bt-luxe",
        parentCategory: "lifestyle",
        size: PAGE_SIZE,
        page,
        excludeIds: articleIds,
        sort: sortByPublishedDate,
      }
    );

    if (response.status !== HttpStatusCode.Ok) return;

    setStories([...stories, ...response.data]);
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-0 md:grid-cols-6 md:gap-x-6 md:gap-y-12 md:pt-9 lg:gap-x-8">
        {stories.map(
          (
            {
              id,
              title: headline,
              media,
              kicker,
              sections,
              urlPath,
              blurb,
              updated: publishedDate,
            },
            index
          ) => {
            const previousIndex = index - 1 > 0 ? index - 1 : 0;
            const previousSeason =
              index <= articles.length
                ? todaysSeason
                : getSeasonBasedOnDate(stories[previousIndex].updated);
            const year = dayjs(publishedDate).year();
            const currentSeason = getSeasonBasedOnDate(publishedDate);
            const isDisplayDivider =
              index > articles.length - 1 && previousSeason !== currentSeason;

            // To hide the article if the current season is undefined.
            // Uncomment this based on Jaimee Ee's reply.
            // if (
            //   typeof currentSeason === "undefined" &&
            //   index > articles.length
            // ) {
            //   return <></>;
            // }

            return (
              <>
                {isDisplayDivider ? (
                  <SeasonDivider
                    season={currentSeason}
                    year={year}
                    className="col-span-1 md:col-span-6"
                  />
                ) : null}

                <div
                  className={cn(
                    "col-span-1 h-full w-full pb-4 pt-5 first:border-t-0 md:col-span-2 md:border-0 md:py-0",
                    {
                      "md:col-span-3": [0, 1].includes(index),
                      "border-t border-gray-175": !isDisplayDivider,
                    }
                  )}
                >
                  <BasicCard
                    key={id}
                    id={id}
                    title={headline}
                    media={media?.[0]}
                    kicker={kicker?.fields?.[0].value}
                    defaultImage={{
                      directoryName: sections?.[0].uniqueName || "",
                    }}
                    blurb={blurb}
                    slug={urlPath}
                    variations={{
                      image: {
                        position: "",
                        width: "w-full",
                        aspectRatio:
                          "aspect-3x2 md:aspect-[348/385] lg:aspect-3x2 group-hover/card:lg:scale-[1.02] transition-all ease-in duration-[350ms] h-auto object-cover w-full",
                        extraClass: "overflow-hidden",
                        filters: [
                          {
                            breakpoint: "(min-width: 992px)",
                            w: 574,
                            h: 382,
                            q: 100,
                            dpr: 1,
                            f: "webp",
                          },
                          {
                            breakpoint: "(min-width: 768px)",
                            w: 348,
                            h: 385,
                            q: 100,
                            dpr: 1,
                            f: "webp",
                          },
                          { w: 351, h: 234, q: 100, dpr: 1, f: "webp" },
                        ],
                      },
                      content: {
                        width: "w-full",
                        extraClass: "pt-3",
                        blurb: {
                          extraClass:
                            "font-oceanSansStd mt-3 !block break-words text-lg font-light leading-[1.3] text-gray-750",
                        },
                      },
                      title: {
                        color: "!text-gray-850",
                        size: "text-4xl md:text-8xl",
                        extraClass:
                          "font-ivyMode my-0 break-words font-semibold uppercase leading-tight transition-all duration-[350ms] hover:underline hover:underline-offset-4",
                      },
                      kicker: {
                        color: "text-verticals-lifestyle-luxe",
                        extraClass:
                          "text-xs tracking-17% font-oceanSansStd font-extrabold mb-1",
                      },
                    }}
                    rootClassName="group/card"
                  />
                </div>
              </>
            );
          }
        )}
      </div>

      <div className="text-center">
        <LoadMore
          loadText="MORE STORIES"
          onLoadMore={loadMore}
          rootClassName={cn(
            "bg-gray-850 relative text-white mt-12 px-12 py-3 inline-block mx-auto border-none font-poppins text-base tracking-2% leading-5",
            "[&>button]:font-normal [&>button]:z-10 [&>button]:relative",
            "hover:bg-gray-850 hover:text-white",
            "after:absolute after:top-0 after:bottom-0 after:-left-4 after:bg-verticals-lifestyle-luxe after:skew-x-[30deg] after:right-[calc(100%+1rem)] after:transition-all overflow-hidden after:ease-out after:duration-300 ",
            "hover:after:-right-4"
          )}
        />
      </div>
    </>
  );
}
