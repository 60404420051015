import { PODCAST_KEYWORDS_DATA } from "@pages/Section/layouts/PodcastsPage/util/constants";

const excludedKeywords = [
  "/keywords/garage-newsletter",
  "/keywords/esg-insights",
  "/keywords/thrive-newsletter",
  "/keywords/property-insights",
  "/keywords/asean-business-newsletter",
];

export const excludeTagJson = excludedKeywords.map((keyword) => {
  return {
    nested: {
      path: "data.context.tags",
      query: {
        match_phrase: {
          "data.context.tags.urlPath": keyword,
        },
      },
    },
  };
});

const excludedPodcastKeywords = PODCAST_KEYWORDS_DATA.map((data) => {
  return data.tag.urlPath;
});

export const excludedPodcastKeywordsJSON = excludedPodcastKeywords.map(
  (keyword) => {
    return {
      nested: {
        path: "data.context.tags",
        query: {
          match_phrase: {
            "data.context.tags.urlPath": keyword,
          },
        },
      },
    };
  }
);

export const sortByStickyModifiedDate = [
  { "data.context.displaySetting.sticky": { order: "desc" } }, // Sticky articles first
  { "data.context.edited": { order: "desc" } }, // Sort by updated time in descending order
];

/**
 * Sort articles by the most recent of 'updated' or 'edited' date fields.
 *
 * This function prioritizes articles with the 'sticky' flag, sorting them in descending order.
 * Then, it compares 'updated' and 'edited' fields, sorting based on the most recent date.
 *
 * The sort uses a Painless script to compute the maximum of the two date fields.
 */
export const sortByRecentDate = [
  {
    _script: {
      type: "number",
      script: {
        source:
          "return Math.max(doc['data.context.updated'].value.millis, doc['data.context.edited'].value.millis);",
        lang: "painless",
      },
      order: "desc",
    },
  },
];

/**
 * Sort articles by 'sticky' field and the most recent of 'updated' or 'edited' date fields.
 *
 * This function prioritizes articles with the 'sticky' flag, sorting them in descending order.
 * Then, it compares 'updated' and 'edited' fields, sorting based on the most recent date.
 *
 * The sort uses a Painless script to compute the maximum of the two date fields.
 */
export const sortByStickyAndRecentDate = [
  { "data.context.displaySetting.sticky": { order: "desc" } }, // Sticky articles first
  ...sortByRecentDate,
];

const excludePodcastsQuery = () => {
  const keywords = PODCAST_KEYWORDS_DATA.map((data) => {
    return data.tag;
  });

  return keywords.map((keyword) => {
    return {
      match_phrase: {
        "data.context.tags.uri": {
          query: keyword.uri?.replace("main/", ""),
        },
      },
    };
  });
};

export const excludePodcastsTagJson = {
  nested: {
    path: "data.context.tags",
    query: {
      bool: {
        minimum_should_match: 1,
        should: excludePodcastsQuery(),
      },
    },
  },
};

export const queryFilterByProfileId = (profileId: string) => {
  return {
    bool: {
      filter: {
        bool: {
          should: [
            {
              nested: {
                path: "data.context.authors.profiles",
                query: {
                  match_phrase: {
                    "data.context.authors.profiles.content.id": profileId,
                  },
                },
              },
            },
            {
              nested: {
                path: "data.context.relatedContributorProfile",
                query: {
                  match_phrase: {
                    "data.context.relatedContributorProfile.content.id":
                      profileId,
                  },
                },
              },
            },
          ],
        },
      },
    },
  };
};

export const sortByPublishedDate = [
  { "data.context.updated": { order: "desc" } }, // Sort by updated time in descending order
];

export const breakingNewsSections = [
  "companies-markets",
  "international",
  "singapore",
  "opinion-features",
  "property",
  "lifestyle",
  "wealth",
  "esg",
  "singapore/smes",
  "startups-tech",
  "companies-markets/capital-markets-currencies",
];

export const breakingNewsSectionFilter = breakingNewsSections.map((id) => {
  return {
    nested: {
      path: "data.context.sections",
      query: {
        term: {
          "data.context.sections.uniqueName.keyword": id.replaceAll("/", "_"),
        },
      },
    },
  };
});
