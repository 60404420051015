import { PageProps } from "./types";

/**
 * Helper function to derive the title of the page.
 * @param props The page properties, may include kind, term name and article title.
 * @returns {string}
 */
export const renderPageTitleV2 = (props: PageProps): string => {
  const { kind } = props;

  switch (kind) {
    case "art":
    case "newsletter":
    case "static": {
      const { sectionName, title } = props;

      if (sectionName) {
        return `${title}, ${sectionName} - The Business Times`;
      }

      return `${title} - The Business Times`;
    }
    case "custom": {
      const { title } = props;
      return title;
    }

    case "section":
    case "keyword":
    case "author":
    case "storythread": {
      const { termName } = props;
      return `${termName} Latest News & Headlines - The Business Times`;
    }

    case "home":
    default:
      return "The Business Times - Get the Latest Business & Financial News";
  }
};
