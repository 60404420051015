import { useEffect } from "react";
import type { ButtonProps as AriaButtonProps } from "react-aria-components";
import Button from "@components/AccessibleComponents/Button";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

export type SubscribeProps = AriaButtonProps & {
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Subscribe(props: SubscribeProps): React.ReactElement {
  const sendDataToGTM = useGTMDispatch();
  const { setIsModalOpen } = props;

  const handleClick = () => {
    if (typeof setIsModalOpen === "undefined") return;

    sendDataToGTM({
      event: "custom_event",
      eventCategory: "bt subshare",
      eventAction: "click",
      eventLabel: "the gift link has expired::subscribe",
      nonInteraction: false,
    });

    setIsModalOpen(false);
  };

  useEffect(() => {
    sendDataToGTM({
      event: "custom_event",
      eventCategory: "bt subshare",
      eventAction: "visible",
      eventLabel: "the gift link has expired",
      nonInteraction: false,
    });
  }, [sendDataToGTM]);

  return (
    <Button
      {...props}
      onPress={handleClick}
      className="w-full rounded-full bg-orange-300 px-9 py-3 font-poppins text-base font-semibold text-white outline-none"
    >
      SUBSCRIBE
    </Button>
  );
}
