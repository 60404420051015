import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

/**
 * Helper function to get the season based on the date
 * @param date The date to get the season from
 * @returns {string|undefined} The season based on the date
 */
export const getSeasonBasedOnDate = (inputDate: string): string | undefined => {
  const d = dayjs(inputDate);

  const seasonArray = [
    {
      name: "Chinese New Year",
      range: {
        from: dayjs(`January 01, ${d.year()} 00:00:00`),
        to: dayjs(`January 31, ${d.year()} 23:59:59`),
      },
    },
    {
      name: "Spring",
      range: {
        from: dayjs(`April 01, ${d.year()} 00:00:00`),
        to: dayjs(`April 30, ${d.year()} 23:59:59`),
      },
    },
    {
      name: "Summer",
      range: {
        from: dayjs(`July 01, ${d.year()} 00:00:00`),
        to: dayjs(`July 31, ${d.year()} 23:59:59`),
      },
    },
    {
      name: "Autumn",
      range: {
        from: dayjs(`September 01, ${d.year()} 00:00:00`),
        to: dayjs(`September 30, ${d.year()} 23:59:59`),
      },
    },
    {
      name: "Christmas",
      range: {
        from: dayjs(`December 01, ${d.year()} 00:00:00`),
        to: dayjs(`December 31, ${d.year()} 23:59:59`),
      },
    },
  ];

  const season = seasonArray.find(({ range: { from, to } }) => {
    return dayjs(inputDate).isBetween(from, to, null, "[]"); // Brackets [] means from and to dates are inclusive
  });

  if (typeof season === "undefined") return;

  return season.name;
};
