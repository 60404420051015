import { useEffect, useState } from "react";
import { ArticleDataObject } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";

export type WealthTopStoriesProps = {
  data: ArticleDataObject[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function WealthTopStories({
  data = [],
}: WealthTopStoriesProps): React.ReactElement {
  const [topStory, setTopStory] = useState<ArticleDataObject>();
  const [subStories, setSubStories] = useState<ArticleDataObject[]>([]);

  const variant = {
    image: {
      width: "w-full",
    },
    content: {
      extraClass:
        "text-white left-0 bottom-0 right-0 md:right-1/2 md:top-0 bg-gradient-to-b md:bg-gradient-to-l justify-center flex flex-col absolute order-2 from-transparent to-black p-3",
    },
    title: {
      size: "text-lg md:text-6xl",
    },
  } as CardVariations;

  const variant2: CardVariations = {
    kicker: {
      color: "text-gray-515",
    },
    image: {
      width: "w-3/10 lg:w-full",
      position: "left",
      filters: [
        { breakpoint: "(min-width: 768px)", w: 400, h: 267, dpr: 1, f: "webp" },
        { w: 300, h: 200, dpr: 1, f: "webp" },
      ],
      extraClass: "items-baseline",
    },
    content: {
      width: "w-7/10 lg:w-full",
      extraClass: "pt-0 pl-4 lg:pl-0 lg:pt-4",
      blurb: {
        extraClass: "text-gray-850",
      },
    },
  };

  useEffect(() => {
    setTopStory(data?.[0]);
    setSubStories(data?.slice(1, 9));
  }, [data]);

  return (
    <>
      <Row data-testid="wealth-top-stories-component">
        {topStory ? (
          <Column rootClassName="pb-6 w-full" key={1}>
            <BasicCard
              id={topStory.id}
              kicker={topStory.kicker?.fields?.[0]?.value}
              title={topStory.title}
              section={topStory.sections?.[0]}
              media={topStory.media}
              paywall={topStory.paywall?.contentAccess === "1"}
              blurb={topStory.blurb}
              variations={variant}
              slug={topStory.slug}
              defaultImage={{
                directoryName: "wealth_wealth-investing",
              }}
            />
          </Column>
        ) : (
          <></>
        )}
        <>
          {subStories.length > 1 ? (
            subStories.map((article) => {
              return (
                <Column
                  rootClassName="pb-6 w-full md:w-6/12 lg:w-3/12 border-b border-gray-175 mb-4 md:border-0 md:mb-0"
                  key={article.id}
                >
                  <BasicCard
                    id={article.id}
                    kicker={article.kicker?.fields?.[0]?.value}
                    title={article.title}
                    section={article.sections?.[0]}
                    media={article.media}
                    paywall={article.paywall?.contentAccess === "1"}
                    created={article.created}
                    slug={article.slug}
                    defaultImage={{
                      directoryName: "wealth_wealth-investing",
                    }}
                    variations={variant2}
                    rootClassName="items-stretch"
                  />
                </Column>
              );
            })
          ) : (
            <></>
          )}
        </>
      </Row>
    </>
  );
}
