import { RouteFactory } from "@app/routePaths";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { LifestyleLuxeContext } from "@app/types/Page";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import BasicCard from "@components/ArticleCard/BasicCard";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import { getSeasonBasedOnDate } from "@pages/Section/layouts/Lifestyle/helpers";
import { sectionNavigationItems } from "@util/constant";
import { GoogleAdsSlotFactory } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";
import dayjs from "dayjs";

import LifestyleLuxeArticles from "./LifestyleLuxeArticles";
import SeasonDivider from "./SeasonDivider";

type LifestyleLuxeContentProps = Pick<LifestyleLuxeContext, "data">;

export default function LifestyleLuxeContent({
  data: { title, articles },
}: LifestyleLuxeContentProps): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({
      targeting: { key: "page", value: "listing" },
    });
  const { breadcrumbsRef, isShowBreadCrumb } = useBreadCrumbState();

  const adSlot = RouteFactory.lifestyleLuxe.replace("/", "");
  const lifestyleUniqueName = RouteFactory.lifestyle.replace("/", "");
  const luxeUniqueName = RouteFactory.lifestyleLuxe.replace("/lifestyle/", "");

  const firstArticle = articles[0];

  if (!firstArticle) return <></>;

  const {
    id,
    title: headline,
    media,
    sections,
    urlPath,
    kicker,
  } = firstArticle;

  const today = new Date();
  const todaysSeason = getSeasonBasedOnDate(today.toString());

  return (
    <div className="w-full">
      <MetaTags
        title={title}
        description="THE BUSINESS TIMES BT Luxe - Find BT Luxe News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times."
        ogType="article"
        slug={RouteFactory.lifestyleLuxe}
      />

      <GAData
        level2={RouteFactory.lifestyle.replace("/", "")}
        chapter1={"bt luxe"}
        title={title}
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Advertisement
        rootClassName="min-h-[90px] bg-gray-125 py-1 md:py-3 border-b border-gray-175"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(adSlot),
          adsClassName:
            "min-h-[50px] lg:min-h-[250px] flex flex-col justify-center items-center",
        }}
      />

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial(adSlot)}
        {...{
          isPrestitialEnabled,
          topOverlayImpressions,
          topOverlayValidity,
        }}
      />

      <Header
        displayBreadcrumb={isShowBreadCrumb}
        parentCategory={lifestyleUniqueName}
        childCategory={luxeUniqueName}
        categoryDataMapping={sectionNavigationItems}
      />

      <Container>
        <h1 className="pb-3 pt-6 font-playfair text-8xl font-semibold text-gray-850">
          <span className="text-10xl">L</span>IFESTYLE
        </h1>

        <div ref={breadcrumbsRef}>
          <Breadcrumb
            className="container m-auto"
            parentCategory={lifestyleUniqueName}
            childCategory={luxeUniqueName}
            categoryDataMapping={sectionNavigationItems}
          />
        </div>
      </Container>

      <Container rootClassName="pb-8">
        <BasicCard
          id={id}
          title={headline}
          media={media?.[0]}
          kicker={kicker?.fields?.[0].value}
          defaultImage={{ directoryName: sections?.[0].uniqueName || "" }}
          slug={urlPath}
          variations={{
            image: {
              position: "",
              width: "w-full",
              aspectRatio: "aspect-3x2 md:aspect-[114/115] lg:aspect-[236/115]",
              extraClass: "overflow-hidden",
              filters: [
                {
                  breakpoint: "(min-width: 992px)",
                  w: 1180,
                  h: 575,
                  q: 100,
                  dpr: 1,
                  f: "webp",
                },
                {
                  breakpoint: "(min-width: 768px)",
                  w: 720,
                  h: 575,
                  q: 100,
                  dpr: 1,
                  f: "webp",
                },
                { w: 351, h: 234, q: 100, dpr: 1, f: "webp" },
              ],
            },
            content: {
              width: "w-full",
              extraClass:
                "relative mx-0 bg-white py-3 lg:py-6 text-left md:-top-10 md:mx-16 md:px-6 md:text-center lg:mx-24 lg:px-28",
            },
            title: {
              color: "!text-gray-850",
              size: "text-4xl md:text-8xl",
              extraClass:
                "md:text-center font-semibold leading-tight hover:underline hover:underline-offset-4 font-ivyMode uppercase transition-all duration-[350ms]",
            },
            kicker: {
              color: "text-verticals-lifestyle-luxe",
              extraClass:
                "text-xs tracking-17% font-oceanSansStd font-extrabold",
            },
          }}
          rootClassName="mt-7"
        />

        <SeasonDivider
          season={todaysSeason}
          year={dayjs(today).year()}
          className="col-span-1 lg:col-span-6"
        />

        <LifestyleLuxeArticles
          articles={articles.slice(1)}
          articleIds={articles.map(({ id }) => id)}
          todaysSeason={todaysSeason}
        />
      </Container>

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.CATFISH,
          slot: GoogleAdsSlotFactory.catfish(adSlot),
        }}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.ABM,
          slot: GoogleAdsSlotFactory.abm(),
        }}
      />
    </div>
  );
}
