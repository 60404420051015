import type { UserInfoType } from "@app/hooks/useUserInfo";
import cx from "classnames";

import { UserInfoModal } from "./UserInfoModal";
import UserInfoPopover from "./UserInfoPopover";

export type UserInfoProps = {
  className?: string;
  userInfo: UserInfoType;
  closePanel?: () => void;
};

/**
 *
 * @param className top-level css class override
 * @param userInfo user data decoded from JWT
 * @see https://docs.google.com/document/d/1obM9zIuU0Cj6UlI3mkHXGWDEIAGpCx029iiuV_kEwUU/edit# for API usage of window._mySPHObj
 */
export default function UserInfo({
  className,
  userInfo,
}: UserInfoProps): React.ReactElement {
  return (
    <div
      className={cx("relative", className)}
      data-testid="user-info-component"
    >
      <UserInfoPopover userInfo={userInfo} />
      <UserInfoModal userInfo={userInfo} />
    </div>
  );
}
