import { useEffect, useState } from "react";
import { isIOS } from "react-device-detect";
import { Link } from "react-router-dom";
import { ANDROID_APP_LINK, IOS_APP_LINK } from "@components/Footer/constant";
import Modal from "@components/Modal/Modal";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { SCREEN_SM } from "@util/constant";
import Cookies from "js-cookie";

export default function AppBanner(): React.ReactElement {
  const sendDataToGTM = useGTMDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClose = () => {
    setIsModalOpen(false);
    sendDataToGTM({
      event: "custom_event",
      eventCategory: "bottom sheet",
      eventAction: "click",
      eventLabel: "continue in mobile web",
      nonInteraction: false,
    });
  };
  const onLinkClick = () => {
    sendDataToGTM({
      event: "custom_event",
      eventCategory: "bottom sheet",
      eventAction: "click",
      eventLabel: "open the app",
      nonInteraction: false,
    });
  };

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (window.innerWidth === 0) return;
    if (window.innerWidth > SCREEN_SM) return;

    const cookie = Cookies.get("BT-APP-BANNER");

    if (typeof cookie !== "undefined") return;
    if (cookie === "displayed") return;

    setIsModalOpen(true);

    Cookies.set("BT-APP-BANNER", "displayed");

    sendDataToGTM({
      event: "custom_event",
      eventCategory: "bottom sheet",
      eventAction: "visible",
      eventLabel: window.location.href,
      nonInteraction: false,
    });
  }, [sendDataToGTM]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      dialogPanelClassNames="absolute left-0 right-0 bottom-0"
    >
      <div className="px-10 pb-6 pt-10">
        <h3 className="mb-4 text-center font-lct text-3xl font-bold">
          Enjoy a better reading experience on BT app
        </h3>

        <Link
          to={isIOS ? IOS_APP_LINK : ANDROID_APP_LINK}
          target="blank"
          rel="noopener noreferer"
          className="mb-3 block rounded-full bg-gray-850 px-6 py-4 font-public-sans text-xs font-semibold text-white"
          onClick={onLinkClick}
        >
          Open the app
        </Link>

        <button
          onClick={onClose}
          className="block w-full rounded-full border border-gray-850 bg-white px-6 py-4 text-xs font-semibold text-gray-850"
        >
          Continue in mobile web
        </button>
      </div>
    </Modal>
  );
}
