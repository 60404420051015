import { Link } from "react-router-dom";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { events } from "@pages/Thrive/constants";

const EventItem = ({
  title = "",
  date = "",
  link = "",
  link_text = "",
}: {
  title?: string;
  date?: string;
  link?: string;
  link_text?: string;
}): React.ReactElement => {
  return (
    <li className="flex flex-col items-start justify-between space-y-6 border-b border-gray-850 px-7 py-12 shadow-gray-850 hover:border hover:bg-white hover:shadow-thrive md:space-x-4 lg:flex-row lg:items-center lg:space-y-0">
      <h2 className="thrive-past-event-title mb-0 flex items-center text-start font-interBold text-11xl font-bold leading-[2.8rem] text-white text-shadow-thrive-2 lg:w-5/10 lg:max-w-xl">
        {title}
      </h2>
      <p className="!ml-0 mb-0 w-40 text-gray-850">{date}</p>
      {link ? (
        <Link
          target="_blank"
          to={link}
          className="!ml-0 flex items-center justify-center space-x-3.5 self-stretch border-2 border-orange-400 bg-white px-8 py-4 font-inter text-base font-semibold uppercase text-gray-850 hover:bg-orange-400 hover:text-white sm:px-12 lg:self-center lg:px-10"
        >
          {link_text ? (
            <span className="min-w-[9.5rem] text-center">{link_text}</span>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faInstagram}
                size="2xl"
                className="h-[27px] w-[26px]"
              />
              <span>View Photos</span>
            </>
          )}
        </Link>
      ) : (
        <p className="!ml-0 flex items-center justify-center space-x-3.5 self-stretch px-8 py-4 font-inter text-base uppercase text-gray-850">
          No Photos Available
        </p>
      )}
    </li>
  );
};

export default function ThrivePastEvents() {
  return (
    <div
      id="past-events"
      className="container px-5 pb-12 pt-8 font-poppins md:px-0 lg:pb-44"
    >
      <div className="font-inter">
        <h3 className="flex justify-center border-b border-gray-850 py-12 text-10xl font-normal text-gray-850 lg:border-none lg:py-0 lg:pb-12">
          Past events<span className="text-green-400">.</span>
        </h3>
        <ul>
          {events.map(({ title, link, date, link_text }) => (
            <EventItem
              key={title}
              title={title}
              link={link}
              date={date}
              link_text={link_text}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
