import { useEffect, useRef, useState } from "react";
import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import { useWindowSize } from "@hooks/useWindowSize";
import {
  FilteringfacetedObjectEnum,
  SearchFilteringProps,
} from "@pages/Search/types";
import { cn } from "@util/helpers";

import SearchAdvanceFiltering from "./SearchAdvanceFiltering/SearchAdvanceFiltering";
import SearchSortFiltering from "./SearchSortFiltering";

export default function SearchFiltering({
  sortBy,
  setSortBy,
  facetedSearchAllData,
  setSelectedFacetedObj,
  selectedFacetedAllData,
}: SearchFilteringProps): React.ReactElement {
  const [openFiltering, setOpenFiltering] = useState(false);
  const [childrenHeight, setChildrenHeight] = useState(0);
  const childrenRef = useRef(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (childrenRef.current) {
      setChildrenHeight((childrenRef.current as HTMLDivElement).scrollHeight);
    }
  }, [windowSize]);

  const handleSelectedFaceted = (
    facetedKey: FilteringfacetedObjectEnum,
    facetedValue: string
  ) => {
    setSelectedFacetedObj(facetedKey, facetedValue);
  };

  return (
    <div data-testid="search-filtering-component" className="mb-6 mt-4">
      <div className="flex items-center gap-4 border-b border-gray-175">
        <SearchSortFiltering
          sortBy={sortBy}
          setSortBy={(sort) => {
            setSortBy(sort);
          }}
        />

        <button
          onClick={() => {
            setOpenFiltering(!openFiltering);
          }}
          className={cn(
            "ml-auto flex items-center font-poppins text-gray-550 hover:text-gray-850",
            {
              "text-gray-850": openFiltering,
            },
            REMOVE_FOCUS_CSS
          )}
        >
          <p className="m-0">Filter</p>

          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={cn("rotate-180 transition-all duration-150", {
              "rotate-0": openFiltering,
            })}
          >
            <path
              d="M6 15L12 9L18 15"
              style={{ stroke: "currentcolor" }}
              strokeWidth="1.5"
            />
          </svg>
        </button>
      </div>

      <div
        style={{ height: openFiltering ? childrenHeight : 0 }}
        className="!before:h-0 !after:h-0 transition-height overflow-hidden duration-300"
      >
        <div
          ref={childrenRef}
          className="ml-auto w-full justify-end gap-x-8 gap-y-1 pt-1 md:flex"
        >
          <SearchAdvanceFiltering
            facetedSearchAllData={facetedSearchAllData}
            setSelectedFacetedObj={(
              facetedKey: FilteringfacetedObjectEnum,
              facetedValue: string
            ) => {
              handleSelectedFaceted(facetedKey, facetedValue);
            }}
            selectedFacetedAllData={selectedFacetedAllData}
          />
        </div>
      </div>
    </div>
  );
}
