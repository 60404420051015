import { Fragment, useState } from "react";
import { useInView } from "react-intersection-observer";
import { ArticleDataObject, seeAlso, Tag } from "@app/types/Cue";
import { AdvertisementTypeEnum } from "@app/types/enums";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Container from "@components/Container/Container";
import Header from "@components/Header/Header";
import { useArticleHeaderState } from "@components/Header/helpers";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useArticleGiftReceived from "@hooks/useArticleGiftReceived";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import { useGrapeshotData } from "@hooks/useGrapeshot";
import { useNextArticle } from "@hooks/useNextArticle";
import { getFormattedTextForAds } from "@pages/Section/helpers";
import { GoogleAdsSlotFactory, isPathAVerticalPath } from "@util/helpers";

import AppBanner from "./components/AppBanner";
import ArticleSubshareModal from "./components/ArticleSubshare/ArticleSubshareModal";
import ArticleSubsharePrompt from "./components/ArticleSubshare/ArticleSubsharePrompt";
import ArticleSubshareReceived from "./components/ArticleSubshare/ArticleSubshareReceived";
import { LAMBDA_RESPONSE_TYPES } from "./types/Alacrity";
import { ArticleFactory, VerticalHeaderFactory } from "./utils/helpers";

type ArticleContentProps = {
  context: ArticleDataObject;
  sectionNews: ArticleDataObject[];
  seeAlso: seeAlso;
  isPreview?: boolean;
};

type ScrollObjArray = {
  id: string;
  scrollW: number;
};

export default function ArticleContent({
  context,
  sectionNews,
  seeAlso,
  isPreview,
}: ArticleContentProps): React.ReactElement {
  const [showGiftSubscribePrompt, setShowGiftSubscribePrompt] = useState(false);
  const [articleInView, setArticleInView] = useState<ArticleDataObject>();
  const [scrollObj, setScrollObj] = useState<ScrollObjArray[]>([]);
  const {
    isPrestitialEnabled,
    topOverlayImpressions,
    topOverlayValidity,
    isOutbrainEnabled,
  } = useGlobalAdSetting({
    targeting: [{ key: "page", value: "article" }],
  });
  const { isModalOpen, setIsModalOpen, alacrityRes, isGiftReceived } =
    useArticleGiftReceived();

  const { articleTopRef, isShowArticleHeader } = useArticleHeaderState();
  const { isLoading, moreStories, ref } = useNextArticle(context);

  const { gsChannels } = useGrapeshotData(moreStories[0].urlPath, -1);

  // Article data de-construct
  const { sections } = context;

  const mainSection = sections?.[0];
  const uniqueName = mainSection?.uniqueName;
  const uniqueNameAds = uniqueName?.replace("_", "/") || "";
  const verticalPath = "/" + uniqueNameAds;
  const isThisArticlegifted =
    alacrityRes?.data.responseType ===
      LAMBDA_RESPONSE_TYPES.SUCCESS_VALID_GIFT_TOKEN &&
    !alacrityRes.data.viewGiftStatus;

  const VerticalHeaderLayout = VerticalHeaderFactory({ verticalPath });

  const { ref: showGiftSubscribePromptRef } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView && isThisArticlegifted) setShowGiftSubscribePrompt(true);
    },
  });

  const handleSetArticle = (e: ArticleDataObject) => {
    setArticleInView(e);
  };

  const handleSetScrollW = (id: string, e: number) => {
    const percent = e >= 100 ? 100 : e;
    const tmpA = scrollObj || [];
    const tmpAIndex = tmpA?.findIndex((x) => x.id === id);

    if (tmpAIndex >= 0) {
      tmpA[tmpAIndex].scrollW = percent;
    } else {
      tmpA.push({
        id: id,
        scrollW: percent,
      });
    }

    setScrollObj(tmpA);
  };

  const parentPath = articleInView
    ? articleInView.urlPath.replace("/", "")
    : "";

  return (
    <div className="w-full" data-testid="article-content-component">
      {!isPathAVerticalPath(verticalPath) ? (
        <>
          <Advertisement
            rootClassName="min-h-[50px] lg:min-h-[250px] bg-gray-125 py-1 md:py-3 border-b border-gray-175"
            adUnitProps={{
              type: AdvertisementTypeEnum.LB1,
              slot: GoogleAdsSlotFactory.lb1(uniqueNameAds),
              slotTargettings: [
                { key: "btarticleid", value: context?.id },
                {
                  key: "bttags",
                  value:
                    context?.tags
                      ?.map((tag: Tag) => getFormattedTextForAds(tag.name))
                      .join(",") || "",
                },
                { key: "gs_channels", value: gsChannels },
              ],
              adsClassName:
                "min-h-[50px] lg:min-h-[250px] flex flex-col justify-center items-center",
            }}
          />
        </>
      ) : null}

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial(uniqueNameAds)}
        slotTargettings={[
          { key: "btarticleid", value: context?.id },
          {
            key: "bttags",
            value:
              context?.tags
                ?.map((tag: Tag) => getFormattedTextForAds(tag.name))
                .join(",") || "",
          },
          { key: "gs_channels", value: gsChannels },
        ]}
        {...{ isPrestitialEnabled, topOverlayImpressions, topOverlayValidity }}
      />

      <Header
        article={articleInView}
        displayArticle={isShowArticleHeader}
        scrollObj={scrollObj}
        parentCategory={parentPath.split("/")[0]}
      />

      <>
        {VerticalHeaderLayout ? (
          <Container rootClassName="py-2">
            <VerticalHeaderLayout
              slotTargettings={[
                { key: "btarticleid", value: context?.id },
                {
                  key: "bttags",
                  value:
                    context?.tags
                      ?.map((tag: Tag) => getFormattedTextForAds(tag.name))
                      .join(",") || "",
                },
                { key: "gs_channels", value: gsChannels },
              ]}
            />
          </Container>
        ) : null}

        <div ref={articleTopRef} />

        <div id="article-content-wrapper" className="w-full">
          {moreStories.map((article, index) => {
            // Check if article is undefined
            if (!article) return null;

            const displayType = article.displaySetting?.displayType;
            const ArticleLayout = ArticleFactory({ displayType });

            if (!ArticleLayout) return null;

            return (
              <Fragment key={article.id}>
                <ArticleLayout
                  article={article}
                  isGiftReceived={isGiftReceived}
                  sectionNews={sectionNews}
                  variant="default"
                  index={index}
                  seeAlso={seeAlso}
                  isNavigate={moreStories.length > 1}
                  setArticleObj={(e) => {
                    handleSetArticle(e);
                  }}
                  setArticleScrollWidth={(id, e) => {
                    handleSetScrollW(id, e);
                  }}
                  isOutbrainEnabled={isOutbrainEnabled}
                  isPreview={isPreview}
                  gsChannels={gsChannels}
                />
                <div ref={ref}></div>
              </Fragment>
            );
          })}
        </div>

        <ArticleSubsharePrompt
          isModalOpen={showGiftSubscribePrompt}
          setIsModalOpen={setShowGiftSubscribePrompt}
        />

        <div
          aria-roledescription="article-subshare-prompt-observer"
          ref={showGiftSubscribePromptRef}
        ></div>

        {isLoading ? (
          <div className="text-center">
            <FontAwesomeIcon icon={faSpinner} size="5x" spin className="mb-6" />
          </div>
        ) : null}

        <Advertisement
          adUnitProps={{
            type: AdvertisementTypeEnum.CATFISH,
            slot: GoogleAdsSlotFactory.catfish(uniqueNameAds),
            slotTargettings: [
              { key: "btarticleid", value: context?.id },
              {
                key: "bttags",
                value:
                  context?.tags
                    ?.map((tag: Tag) => getFormattedTextForAds(tag.name))
                    .join(",") || "",
              },
              { key: "gs_channels", value: gsChannels },
            ],
          }}
        />

        <Advertisement
          adUnitProps={{
            type: AdvertisementTypeEnum.ABM,
            slot: GoogleAdsSlotFactory.abm(),
            slotTargettings: [
              { key: "btarticleid", value: context?.id },
              {
                key: "bttags",
                value:
                  context?.tags
                    ?.map((tag: Tag) => getFormattedTextForAds(tag.name))
                    .join(",") || "",
              },
              { key: "gs_channels", value: gsChannels },
            ],
          }}
        />

        <>
          {alacrityRes ? (
            <ArticleSubshareModal
              isModalOpen={isModalOpen && !alacrityRes.data.viewGiftStatus}
              setIsModalOpen={setIsModalOpen}
            >
              <ArticleSubshareReceived
                alacrityRes={alacrityRes}
                setIsModalOpen={setIsModalOpen}
              />
            </ArticleSubshareModal>
          ) : null}
        </>
      </>

      <AppBanner />
    </div>
  );
}
