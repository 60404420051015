import { useEffect } from "react";
import type { ButtonProps as AriaButtonProps } from "react-aria-components";
import Button from "@components/AccessibleComponents/Button";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

export type StartReadingProps = AriaButtonProps & {
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function StartReading(
  props: StartReadingProps
): React.ReactElement {
  const sendDataToGTM = useGTMDispatch();
  const { setIsModalOpen } = props;

  const handleClick = () => {
    if (typeof setIsModalOpen === "undefined") return;

    sendDataToGTM({
      event: "custom_event",
      eventCategory: "bt subshare",
      eventAction: "click",
      eventLabel: "you've successfully redeemed your gift::start reading",
      nonInteraction: false,
    });

    setIsModalOpen(false);
  };

  useEffect(() => {
    sendDataToGTM({
      event: "custom_event",
      eventCategory: "bt subshare",
      eventAction: "visible",
      eventLabel: "you've successfully redeemed your gift",
      nonInteraction: false,
    });
  }, [sendDataToGTM]);

  return (
    <Button
      {...props}
      onPress={handleClick}
      className="w-full rounded-full bg-black px-9 py-3 font-poppins text-base font-semibold text-white outline-none"
    >
      START READING
    </Button>
  );
}
