import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { cn } from "@util/helpers";
import cx from "classnames";

export type ModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  dialogPanelClassNames?: string;
};

export default function Modal({
  isModalOpen,
  setIsModalOpen,
  children,
  dialogPanelClassNames,
}: ModalProps): React.ReactElement {
  return (
    <Transition show={isModalOpen}>
      <Dialog
        className="relative z-[999999]"
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <TransitionChild
          enter="ease-out duration-300 opacity-0"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
        </TransitionChild>

        {/* Full-screen container to center the panel */}
        <div
          className={cx(
            "fixed inset-0 flex w-screen items-center justify-center"
          )}
        >
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-8"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-8"
          >
            <DialogPanel
              className={cn(
                "relative max-h-full w-full max-w-full overflow-y-auto rounded-t-3xl bg-white text-center",
                "md:w-[25rem] md:rounded-none",
                dialogPanelClassNames
              )}
            >
              {children}
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
