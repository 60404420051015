import { useEffect } from "react";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

import ArticleSubshareModal from "./ArticleSubshareModal";
import ArticleSubsharePromptContent from "./ArticleSubsharePromptContent";

type ArticleSubsharePromptProps = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ArticleSubsharePrompt({
  isModalOpen,
  setIsModalOpen,
}: ArticleSubsharePromptProps): React.ReactElement {
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    if (!isModalOpen) return;

    sendDataToGTM({
      event: "custom_event",
      eventCategory: "bt subshare",
      eventAction: "visible",
      eventLabel: "get unlimited access to premium bt stories",
      nonInteraction: false,
    });
  }, [isModalOpen, sendDataToGTM]);

  return (
    <ArticleSubshareModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <ArticleSubsharePromptContent setIsModalOpen={setIsModalOpen} />
    </ArticleSubshareModal>
  );
}
