import { Fragment } from "react";
import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { cn } from "@util/helpers";

import { UserInfoProps } from "./UserInfo";
import UserInfoPanel from "./UserInfoPanel";

export default function UserInfoPopover({
  userInfo,
}: UserInfoProps): React.ReactElement {
  return (
    <Popover
      className="group hidden lg:block"
      data-testid="user-info-popover-component"
    >
      <PopoverButton
        className={cn(
          "hidden h-9 items-center justify-center rounded-[25px] px-3 font-poppins text-2xs font-semibold leading-none tracking-tightest text-gray-850 duration-300 lg:flex lg:hover:bg-gray-250",
          REMOVE_FOCUS_CSS
        )}
      >
        <p className="mb-0">Account</p>
      </PopoverButton>

      <Transition>
        <TransitionChild
          as={Fragment}
          enter="transition ease duration-500 transform"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease duration-150 transform"
          leaveFrom="opacity-100 "
          leaveTo="opacity-0"
        >
          <PopoverPanel
            anchor={{ to: "bottom end" }}
            className="relative z-50 min-w-[300px] bg-white pb-3 shadow-userInfoPanel"
          >
            {({ close }) => (
              <UserInfoPanel userInfo={userInfo} closePanel={close} />
            )}
          </PopoverPanel>
        </TransitionChild>
      </Transition>
    </Popover>
  );
}
