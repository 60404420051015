import { fetchShortUrl } from "@pages/Article/Article.server";
import { RouteFactory } from "@src/app/routePaths";
import { getEnvironment } from "@util/constant";
import type { GaCustomEventTrackerObject } from "@util/helpers";
import { getEnvironmentDomain } from "@util/helpers";

import type { PulseArticles } from "./types";
import { SanitizedPulseData } from "./types";

const ENVIRONMENT_DOMAIN = getEnvironmentDomain(getEnvironment());

export const getActiveArticleId = (pathname: string): string => {
  const activeArticleId = pathname.split("/");
  const lastItem = activeArticleId[activeArticleId.length - 1];
  return /^[0-9]+$/.test(lastItem) ? lastItem : "";
};

export const filteredPulseArticles = (
  articleId: string,
  pulseArticles: SanitizedPulseData[]
) => {
  const filterActiveArticleId = pulseArticles.filter(
    (p: SanitizedPulseData) => p.id === articleId
  );

  if (filterActiveArticleId.length <= 0) return pulseArticles;

  // remove the item from the array
  const filteredPulseArticles = pulseArticles.filter(
    (p: SanitizedPulseData) => p.id !== articleId
  );

  const firstFilteredArticle = filterActiveArticleId?.[0];

  // add it at the beginning
  filteredPulseArticles.unshift({ ...firstFilteredArticle });
  return filteredPulseArticles;
};

export const getGaDataObject = (
  title: string = "pulse intro",
  virtualUrl: string = "pulse/intro",
  id: string = "",
  articles?: SanitizedPulseData[]
) => {
  const gaObject: GaCustomEventTrackerObject = {
    event: "virtual_pv",
    level2: "pulse",
    contentcat: "free",
    contenttype: "listing",
    title,
    virtual_url: virtualUrl,
  };

  if (id !== "" && articles) {
    const currentSlideData = articles.find((article) => article.id === id);
    gaObject["articleid"] = id;
    gaObject["cue_articleid"] = id;
    gaObject["author"] = currentSlideData?.authors || "(not set)";
    gaObject["pubdate"] = currentSlideData?.published || "(not set)";
    gaObject["title"] = currentSlideData?.title || "(not set)";
    gaObject["contenttype"] = "article";
    gaObject["virtual_url"] =
      currentSlideData?.slug.substring(1) || "(not set)";
  }

  return gaObject;
};

export const getPulseData = (data: PulseArticles[]) => {
  const santitizedData: Promise<SanitizedPulseData>[] = data
    .filter((x) => x.id)
    .map(async (article): Promise<SanitizedPulseData> => {
      const slug = RouteFactory.pulseSlide(article.id);
      const longURL = `${ENVIRONMENT_DOMAIN}${slug}`;
      const shortURL = await fetchShortUrl(longURL);

      return {
        ...article,
        summaries: article.summary.split("|").map((text) => text.trim()),
        shareUrl: shortURL || longURL,
        slug: slug,
      };
    });

  return Promise.all(santitizedData);
};
