import { Fragment, useState } from "react";
import { getIconSrc } from "@components/Button/helpers";
import { IconName } from "@components/Button/types";
import Icon from "@components/Icon/Icon";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import cn from "classnames";

import { UserInfoProps } from "./UserInfo";
import UserInfoPanel from "./UserInfoPanel";

export const UserInfoModal = ({ userInfo }: UserInfoProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = (open: boolean) => {
    setIsOpen(open);
  };

  return (
    <div data-testid="user-info-modal-component" className="block lg:hidden">
      <button
        onClick={() => {
          handleModalState(true);
        }}
      >
        <img
          src={getIconSrc(IconName.profile)}
          width="48"
          height="48"
          alt="hamburger icon"
        />
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          className="relative z-30"
          onClose={() => {
            handleModalState(false);
          }}
        >
          <div className="fixed inset-0 overflow-y-auto">
            <TransitionChild
              enter="transition ease duration-500 transform"
              enterFrom="opacity-0 translate-x-8/10"
              enterTo="opacity-100 translate-x-0"
              leave="transition ease duration-1000 transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <DialogPanel className="h-full w-full transform bg-white transition-all lg:max-w-[260px]">
                <div
                  className={cn(
                    "flex cursor-pointer justify-end border-b border-gray-175 p-2 opacity-100 lg:hidden lg:hover:opacity-75"
                  )}
                  onClick={() => {
                    handleModalState(false);
                  }}
                >
                  <Icon type="close" fill="#2b2b2b" size={30} />
                </div>

                <UserInfoPanel userInfo={userInfo} />
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
