/**
 * Company: SPHMedia
 * Description: Category component which acts as a subcomponent for a card component
 */

import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { cn } from "@util/helpers";

export type CategoryProps = {
  categoryName: string;
  hrefLink?: string;
  rootClassName?: string;
};

/**
 * Category
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function Category({
  categoryName = "",
  rootClassName = "",
  hrefLink,
}: CategoryProps): ReactElement {
  return (
    <>
      {hrefLink ? (
        <Link
          to={hrefLink}
          className={cn(
            "font-poppins text-4xs uppercase text-white hover:underline",
            rootClassName
          )}
          data-testid={"category-component"}
          reloadDocument
        >
          {categoryName}
        </Link>
      ) : null}
    </>
  );
}
