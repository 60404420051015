import { useCallback } from "react";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getGaDataObject } from "@pages/Pulse/utils/helpers";
import { SanitizedPulseData } from "@pages/Pulse/utils/types";

export default function useSendPulseGaData() {
  const sendDataToGTM = useGTMDispatch();

  const sendTrackingData = useCallback(
    (
      title: string,
      virtualUrl: string,
      articleId?: string,
      articles?: SanitizedPulseData[]
    ) => {
      const gaDataObject = getGaDataObject(
        title,
        virtualUrl,
        articleId,
        articles
      );
      sendDataToGTM(gaDataObject);
    },
    [sendDataToGTM]
  );

  const sendCustomEvent = useCallback(
    (eventAction: string, eventLabel: string) => {
      sendDataToGTM({
        event: "custom_event",
        eventCategory: "bt pulse",
        eventAction,
        eventLabel,
      });
    },
    [sendDataToGTM]
  );

  return { sendTrackingData, sendCustomEvent };
}
