import { Await } from "react-router-dom";
import CustomError from "@components/Error/CustomError";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

import { PulseContext } from "./utils/types";
import PulsePageContent from "./PulsePageContent";

export default function PulsePage(): React.ReactElement {
  const { context } =
    useRouteContext<TRouteWithRedirect<PulseContext, string>>();

  // StatusCode is not 200
  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {(context: PulseContext) => {
        const { kind, data } = context;

        // Load article page content.
        if (kind === "static") {
          return <PulsePageContent data={data} />;
        }

        // Default error page.
        return <CustomError statusCode={HttpStatusCode.NotFound} />;
      }}
    </Await>
  );
}
