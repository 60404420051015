import iconBigPdf from "@assets/icon-big-pdf.svg";

export default function TodaysMarketData({
  wealthAttachment,
}: {
  wealthAttachment: string;
}): React.ReactElement {
  return (
    <div className="mb-5 bg-gray-250 p-5">
      <a
        className="text-black hover:no-underline"
        href={wealthAttachment}
        target="_blank"
        rel="noopener noreferrer"
      >
        <h3 className="mb-3 pl-2/10 font-poppins text-4xl font-normal tracking-[1px]">
          {"DOWNLOAD TODAY'S MARKET DATA"}
        </h3>
        <img
          src={iconBigPdf}
          alt="Download Today's Market Data"
          width={56}
          height={56}
          className="mx-auto block"
        />
      </a>
    </div>
  );
}
